import React from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import SplashPage from './pages/splashPage'
import LoginPage from './pages/login/loginPage'

import { navigationInfoService, getPatientsService, getLoginService, getAlertsService } from './services'

import HomePage from './pages/homePage'
import { PatientsPage, PATIENTS_ROUTE } from './pages/patients/patientsPage'
import { StatusPage, STATUS_ROUTE } from './pages/status/statusPage'

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

const HOME_ROUTE = '/home'

const WEP_API_HOST = process.env.REACT_APP_WEP_API_HOST || ''
const WEB_API_BASE = '/api'

/**
 * The data structure that contains all the menu navigation links that are currently known by the client
 * application. Each entry is characterized by its identifier (i.e. key used in the registry) and associated 
 * object. The entry identifier is the rel type to which the entry is associated in the API's home document.
 * The associated object is comprised of the following properties: 
 *  @property {string} iconName     - style information to be used when rendering the menu item
 *  @property {string} text         - text label to be used when rendering the menu item
 *  @property {string} link         - the URL for the client Route
 *  @property {function} renderProp - the function used as a render prop
 */
const navigationRegistry = function () {
    const registry = new Map()
    registry.set('home', { 
        iconName: 'plus', text: 'Home', link: HOME_ROUTE, 
        renderProp: (props) => <HomePage {...props} content={props.content} activeKey={HOME_ROUTE} service={getAlertsService()} />
    }) 
    registry.set('patients', { 
        iconName: 'address card', text: 'Patients', link: PATIENTS_ROUTE, 
        renderProp: (props) => <PatientsPage {...props} content={props.content} activeKey={PATIENTS_ROUTE} service={getPatientsService()} /> 
    }) 
    registry.set('status', { 
        iconName: 'heartbeat', text: 'Status', link: STATUS_ROUTE, 
        renderProp: (props) => <StatusPage {...props} content={props.content} activeKey={STATUS_ROUTE} service={getPatientsService()} />
    }) 
    return registry    
}()

/**
 * Function that returns the navigation information that is to be used to build the application's
 * navigation menu. The navigation menu is built from the links in the set of links produced by 
 * the server and that are known by the client.
 * 
 * @param {object} navigationInfo   - The navigation information
 * @return {object}                 - the view model for the navigation menu
 */
function buildNavigationMenu(navigationInfo) {
    const navigationMenuModel = { api: navigationInfo.api, resources: navigationInfo.resources, items: [] }
    navigationMenuModel.items.push(navigationRegistry.get('home'))
    Object.getOwnPropertyNames(navigationInfo.resources).forEach(propertyName => {
        if (navigationRegistry.has(propertyName)) {
            navigationMenuModel.items.push(navigationRegistry.get(propertyName))
        }
    })
    return navigationMenuModel
}

/**
 * Function that returns the component that corresponds to the home page of the application
 * 
 * @param {object} navInfo      - The navigation information constructed from the information 
 *                                obtained from the server
 * @return {React.Component}    - the component that represents the application's home page
 */
function navigateToHome(navInfo) {
    const navigationMenu = buildNavigationMenu(navInfo)
    const augmentProps = (props) => ({ ...props, content: navigationMenu })
    const itemToRoute = (item) => <Route path={item.link} 
        render={(props) => item.renderProp(augmentProps(props))} 
        key={item.link} />

    ReactDOM.render(
        <Router>
            <Route exact path='/' render={ () => <Redirect to={HOME_ROUTE} /> } />
            { navigationMenu.items.map(itemToRoute) }
        </Router>,
        document.getElementById('root')
    )
}

function renderLoginPage(andThen, errorMessage) {
    ReactDOM.render(<LoginPage service={getLoginService()} onLogin={andThen} errorMessage={errorMessage} />, document.getElementById('root'))
}

function renderSplashPage() {
    ReactDOM.render(
        <SplashPage 
            asyncFetchNavigationInfo = { () => navigationInfoService(`${WEP_API_HOST}${WEB_API_BASE}`) }
            infoLoadedCallback = { (navInfo) => navigateToHome(navInfo) }
            onCredentialsError = { () => renderLoginPage(renderSplashPage, 'Invalid credentials') }
        />,
        document.getElementById('root')
    )
}

(function main() {

    console.log(`WEP_API_HOST = ${WEP_API_HOST}`)
    axios.defaults.baseURL = `${WEP_API_HOST}`
    axios.defaults.timeout = 20000
    
    let loginService = getLoginService()
    if (!loginService.isLoggedIn()) {
        renderLoginPage(renderSplashPage)
    } else {
        renderSplashPage()
    }
})()
