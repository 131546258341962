
/**
 * Creates an instance of the patient view model gfrom the given patient DTO
 * @param {object} patient  - the DTO bearing the patient data
 */
export function patientViewModelFromDto(patient) {
    function getPropertyValue(propertyName) {
        let property = patient.data.find((it) => it.name === propertyName);
        return property ? property.value : property
    }

    return {
        getId: () => getPropertyValue('id'),
        getName: () => getPropertyValue('name'),
        getOrganization: () => getPropertyValue('organization'),
        getSystem: () => getPropertyValue('system'),
        getHeartRate: () => getPropertyValue('hearRate'),
        getAlertEmails: () => getPropertyValue('alertEmails')
    }
}