import React from 'react'

/**
 * Component used to render a spinner.
 * 
 * @prop {string} title    - prop with the title of the spinner
 * @prop {string} message  - prop with the text to be displayed
 */
export function Spinner({title, message}) {
    return (
        <div className='ui icon message'>
            <i className='notched circle loading icon'></i>
            <div className='content'>
                <div className='header'>{title}</div>
                <p>{message}</p>
            </div>
        </div>
    )
}

/**
 * Component used to render an error message.
 * 
 * @prop {string} title    - prop with the title of the error message
 * @prop {string} message  - prop with the text to be displayed
 */
export function ErrorMessage({title, message}) {
    return (
        <div className='ui negative message'>
            <div className='header'>{title}</div>
            <p>{message}</p>
        </div>
    )
}

/**
 * Component used to render a dimissable message.
 * 
 * @prop {string} title     - prop with the title of the message
 * @prop {string} message   - prop with the text content of the message
 * @prop {boolean} positive - prop with a boolean value indicating whether it is a positive message or not
 */
export class DismissableMessage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { visible: true }
    }

    handleDismiss = () => {
        this.setState({ visible: false })
    }

    render() {
        const style = this.props.positive ? 'ui positive floating message' : 'ui floating message'
        return !this.state.visible ? <></> : (
            <div className={style}>
                <i className='close icon' onClick={this.handleDismiss}></i>
                <div className='header'>{this.props.title}</div>
                <p>{this.props.message}</p>
            </div>
        )
    }
}