import React from 'react'

import { Spinner, ErrorMessage } from './messages'

/**
 * Higher Order Component that wrapps another component and adds loading behavior (and UI) to it.
 * 
 * @param {React.Component} WrappedComponent    - The component to which the loading behavior is to be added
 * @param {Number} updatePeriod                 - The interval (expressed in minutes) between reloads. If the argument evaluates to false,
 *                                                the component will not perform reloads
 * @param {function} loaderFunction             - The function to be used to assynchronously load the data of the wrapped component
 * @param {function} completionCallback         - The function to be used to signal completion. Completion is only signaled if the component is still mounted.
 */
export function withLoader(WrappedComponent, updatePeriod, loaderFunction, completionCallback) {


    class WithLoader extends React.Component {

        constructor(props) {
            super(props)
            this.state = { loading: true, error: false }
            this.cancelled = false
        }

        loadData = async () => {
            const result = await loaderFunction()
            if (this.cancelled) return
            this.setState({ data: result, loading: false }) 
            completionCallback(result)
        }

        reloadData = async () => {
            try { await this.loadData() }
            catch(error) { completionCallback(undefined) }
        }
    
        async componentDidMount() {
            try { 
                await this.loadData()
                if (this.cancelled) return
                if (Number.isSafeInteger(updatePeriod)) {
                    this.updaterHandle = setInterval(this.reloadData, updatePeriod * 60 * 1000)
                }
            }
            catch (error) { 
                this.setState({ error: true, loading: false }) 
                completionCallback(undefined)
            }
        }

        async componentWillUnmount() {
            this.cancelled = true
            if (this.updaterHandle) {
                clearInterval(this.updaterHandle)
            }
        }

        render() {
            return this.state.loading ? <Spinner title='Just one second' message='We are fetching that data for you' /> : (
                this.state.error ? <ErrorMessage title='Failed to fetch the data' message='We could not reach our API' /> : 
                    <WrappedComponent {...this.props} loadedData={this.state.data} />
            )
        }
    }

    WithLoader.displayName = `WithLoader(${WrappedComponent.displayName || WrappedComponent.name})`;
    return WithLoader;
}
