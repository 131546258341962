/**
 * Used to diplay a Notification to the user, if the browser supports it and the user allows it
 * 
 * @param {string} title    - The notification title
 * @param {object} options  - The notification options (according to the Notification API)
 */
function displayNotification(title, options) {
    if (!("Notification" in window) || Notification.permission === 'denied') {
        // Not supported or not allowed.
        return
    }

    if (Notification.permission === "granted") {
        new Notification(title, options)
    }
    else {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                new Notification(title, options)
            }
        });
    }
}

/**
 * Displays a notification with the given alert information
 * 
 * @param {object} alertDto - The alert to be displayed in the notification
 */
export function displayAlert(title, content) {
    const options = {
        body: content,
        icon: '/images/alert.png',
        badge: '/images/favicon.ico',
        vibrate: [2000, 1000, 2000]
    }
    displayNotification(title, options)
}