import React from 'react'
import { List, Icon } from 'semantic-ui-react'
import { withLoader } from '../common/loader'

import Menu from '../common/menu'
import { statusViewModelFromDto } from './viewModels'

export const STATUS_ROUTE = '/status'

/**
 * Internal component used to display the status' list
 * 
 * @prop {object} loadedData    - a prop with the collection Json object bearing the status' list
 * @prop {function} onItemClick - a prop with the function that will be called whenever a list item is clicked. 
 *                                The function arguments are:
 *                                  @param {object} item - the list item
 *                                  @param {event} event - the original click event
 */
function StatusList({loadedData, onItemClick}) {
    function itemToListEntry(item) {
        let status = statusViewModelFromDto(item)
        const iconName = status.isHealthy() === true ? 'check' : 'times'
        const iconClass = status.isHealthy() === true ? 'big olive' : 'big red'
        return (
            <List.Item as='a' key={status.getPatientId()} onClick={(evt) => onItemClick(item, evt)}>
                <Icon name={iconName} className={iconClass} />
                <List.Content>
                    <List.Header>{status.getName()}</List.Header>
                    <List.Description> {status.getOrganization()} : &nbsp;{status.getSystem()} </List.Description>
                </List.Content>
            </List.Item>
        )
    }

    return (
        <List animated>
            { loadedData.items.map(itemToListEntry) }
        </List>
    )
}

const RELOAD_INTERVAL_IN_MINUTES = 5

/**
 * Component that represents the application's status page
 * 
 * @prop {object} content   - a prop bearing the navigation information (i.e. the API's home resource) used to build the navigation menu
 * @prop {string} activeKey - a prop bearing the key of the currently active menu item
 * @prop {object} service   - a prop bearing the Patients service to be used (an injected dependency)
 */
export class StatusPage extends React.Component {

    componentWillMount() {
        const statusUrl = this.getStatusResourceUrl()
        if (statusUrl) {
            this.StatusListLoader = withLoader(StatusList, RELOAD_INTERVAL_IN_MINUTES,
                async () => this.props.service.getStatus(statusUrl), 
                (data) => { this.setState({loadedData: data}) }
            )
        }
    }

    getStatusResourceUrl() {
        const resourceDescriptor = this.props.content.resources['status']
        return resourceDescriptor && resourceDescriptor.href
    }
    
    handleItemClick = (item, _event) => {
        console.log(`item clicked: ${JSON.stringify(item)}`)
    }

    render() {
        return (
            <>
                <Menu items={this.props.content.items} activeKey={this.props.activeKey} />
                <div className='ui segment'>
                    <div className='ui centered container'>
                        <this.StatusListLoader onItemClick={this.handleItemClick} />
                    </div>
                </div>
            </>
        )
    }
}
