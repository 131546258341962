
/**
 * Creates an instance of the status view model gfrom the given status DTO
 * @param {object} status  - the DTO bearing the patient status data
 */
export function statusViewModelFromDto(status) {
    function getPropertyValue(propertyName) {
        let property = status.data.find((it) => it.name === propertyName);
        return property ? property.value : property
    }

    return {
        getPatientId: () => getPropertyValue('patientId'),
        getName: () => getPropertyValue('name'),
        getOrganization: () => getPropertyValue('organization'),
        getSystem: () => getPropertyValue('system'),
        isHealthy: () => getPropertyValue('healthy') === 'true',
    }
}