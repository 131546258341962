import React from 'react'

import { FormTextInput, HeartRateInput, EmailInput } from './common'

/**
 * Helper function used to map an entry of the patient instance received from the API to a form input. 
 * This is the function that actually performs the required projection.
 * 
 * @param {object} item             - the item from the Collection JSON template object
 * @param {object} state            - the current state of the form
 * @param {function} changeHandler  - the function to be used as an event handler for changes on the form elements
 * @return {React.Component}        - the corresponding form component
 */
function patientPropertyToFormElement(item, state, changeHandler) {
    const readOnly = state.mode === 'read'
    switch (item.name) {
        case 'heartRate':
            return <HeartRateInput key={item.name} mandatory={false}  
                        descriptor={item} state={state} readOnly={readOnly} changeHandler={changeHandler} />    
        case 'alertEmails':
            return <EmailInput key={item.name} mandatory={false} 
                        descriptor={item} state={state} readOnly={readOnly} changeHandler={changeHandler} />    
        default:
            return <FormTextInput key={item.name} mandatory={true} 
                        descriptor={item} state={state} readOnly={readOnly} changeHandler={changeHandler} />
    }
}

/**
 * Component that represents the application's patient page.
 * 
 * @prop {object} patient   - a prop bearing the Patient data to be displayed. The data is expected to be 
 * as returned by the API's Patients resource, that is, a list item.
 * (see https://heimdallr.challenge-solutions.com/swagger-ui.html#/patients/getAllUsingGET)
 * @prop {object} service   - a prop bearing the Patients service to be used (an injected dependency)
 */
export default class PatientPage extends React.Component {

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleEditButtonEvent = this.handleEditButtonEvent.bind(this)
        this.state = { 
            item: this.props.patient, 
            units: 'minutes', mode: 'read' 
        }
    }

    handleEditButtonEvent(event) {
        event.preventDefault()
    }

    handleChange(event) {
        event.preventDefault()
    }

    render() {
        return (
            <form className='ui equal width form'>
                <div className='ui segment'>
                    <div className='ui container'>
                        <h4 className='ui dividing header'>Patient Information</h4>
                        { 
                            this.state.item.data
                                .map((property) => patientPropertyToFormElement(property, this.state, this.handleChange))
                        }
                    </div>
                </div>
                {/*
                <div className='ui container'>
                    <button className='ui right floated primary basic blue button' type='button'
                            data-tooltip='Edit patient registration info' data-position='left center' onClick={this.handleEditButtonEvent}>
                        <i className='edit icon'></i>Edit
                    </button>
                </div>
                */}
            </form>
        )
    }
}