import React from 'react'
import { NavLink } from 'react-router-dom'

/**
 * Internal component used to render the image displayed at the menu's upper left corner
 * 
 * @param {string} imageSrc - prop that contains the image URL
 */
function ImageItem({imageSrc}) {
    return (
        <div className='item'>
            <img className='ui mini image' src={imageSrc} alt=''/>
        </div>
    )
}

/**
 * Internal component used to render menu items. Each menu item contains an icon and a title
 * 
 * @param {string} icon     - prop with the name of the icon (as defined by semantic UI) to be displayed
 * @param {string} text     - prop with the text to be displayed
 * @param {string} link     - prop with the route to be followed when the icon is clicked
 * @param {Boolean} link    - prop with the active status of the menu item
 */
function MenuItem({icon, text, link, active}) {
    return (
        <NavLink className={'item' + (active ? ' active' : '')} to={link}>
            <i className={icon + ' icon'} /> {text}
        </NavLink>
    )
}

/**
 * Component that renders the application's navigation menu
 * 
 * @param {Array} items         - prop bearing the array of menu items to be displayed
 * @param {string} activeKey    - id of the menu item to be shown as active. We use as ids the menu's item links.
 */
function Menu({items, activeKey}) {
    function itemToMenuItem(item) {
        const active = item.link === activeKey
        return <MenuItem icon={item.iconName} text={item.text} link={item.link} key={item.link} active={active} />
    }

    return (
        <div className='ui pointing menu'>
            <div className='ui container'>
                <ImageItem imageSrc='/images/heart_logo.png' />
                { items.map(itemToMenuItem) }
            </div>
        </div>
    )
}

export default Menu