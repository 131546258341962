import React from 'react'

/**
 * Component used to display a text input within a patient form.
 * 
 * @prop {object} descriptor        - the object that describes the input to be collected (as specified 
 * by a Collection JSON template object)
 * @prop {Boolean} mandatory        - a boolean value indicating whether the input is mandatory or not
 * @prop {object} state             - the current state of the owner form
 * @prop {function} changeHandler   - the function to be used as an event handler for changes on the input's content
 */
export function FormTextInput({descriptor, mandatory, state, changeHandler, readOnly}) {
    let divStyle = 'field'
    const value = state[descriptor.name] || descriptor.value || ''
    let inputComponent = (<input type='text' readOnly={readOnly} name={descriptor.name} placeholder={descriptor.hint} 
            value={value} onChange={changeHandler} />)
    if (!readOnly && mandatory) {
        divStyle = 'required ' + divStyle
        inputComponent = (<input type='text' name={descriptor.name} required placeholder={descriptor.hint} 
            value={value} onChange={changeHandler} />)
    }
    return (
        <div className={divStyle}>
            <label>{descriptor.prompt}</label>
            {inputComponent}
        </div>
    )
}

/**
 * Component used to display an input to collect (or display) the patient's heart rate within the a patient form.
 * 
 * @prop {object} descriptor        - the object that describes the input to be collected (as specified 
 * by a Collection JSON template object)
 * @prop {Boolean} mandatory        - a boolean value indicating whether the input is mandatory or not
 * @prop {object} state             - the current state of the owner form
 * @prop {function} changeHandler   - the function to be used as an event handler for changes on the input's content
 */
export function HeartRateInput({descriptor, mandatory, state, changeHandler, readOnly}) {
    let divStyle = 'field'
    const value = state[descriptor.name] || descriptor.value || ''
    let inputComponent = (<input type='number' readOnly={readOnly} name={descriptor.name} placeholder={descriptor.hint}
        value={value} onChange={changeHandler} />)
    if (!readOnly && mandatory) {
        divStyle = 'required ' + divStyle
        inputComponent = (<input type='number' name={descriptor.name} required placeholder={descriptor.hint}
            value={value} onChange={changeHandler} />)
    }

    return (
        <div className='fields'>
            <div className={divStyle}>
                <label>{descriptor.prompt}</label>
                {inputComponent}
            </div>
            <div className='field'>
                <label>Units</label>
                <select className='ui simple dropdown' readOnly={readOnly} name='units' value={state.units} onChange={changeHandler}>
                    <option value='minutes'>minutes</option>
                    <option value='hours'>hours</option>
                    <option value='days'>days</option>
                </select>
            </div>
        </div>
    )
}

/**
 * Component used to display an input to collect (or display) a comma separated list of emails within the patient form
 * 
 * @prop {object} descriptor        - the object that describes the input to be collected (as specified 
 * by a Collection JSON template object)
 * @prop {Boolean} mandatory        - a boolean value indicating whether the input is mandatory or not
 * @prop {object} state             - the current state of the owner form
 * @prop {function} changeHandler   - the function to be used as an event handler for changes on the input's content
 */
export function EmailInput({descriptor, mandatory, state, changeHandler, readOnly}) {
    let divStyle = 'field'
    const value = state[descriptor.name] || descriptor.value || ''
    let inputComponent = (<input type='email' readOnly={readOnly} multiple name={descriptor.name} placeholder={descriptor.hint} 
            value={value} onChange={changeHandler} />)
    if (!readOnly && mandatory) {
        divStyle = 'required ' + divStyle
        inputComponent = (<input type='email' multiple name={descriptor.name} required placeholder={descriptor.hint} 
            value={value} onChange={changeHandler} />)
    }
    return (
        <div className={divStyle}>
            <label>{descriptor.prompt}</label>
            {inputComponent}
        </div>
    )
}
