import axios from 'axios'
import { Base64 } from 'js-base64'

/**
 * Function used to obtain the service associated to the login
 */
export function getLoginService() {

    function getAuthorizationToken(username, password) {
        let credentials = `${username}:${password}`
        return `Basic ${Base64.encode(credentials)}`
    }

    let USER_INFO_KEY = "USER_INFO_KEY"
    let AUTH_HEADER_KEY = "AUTH_HEADER_KEY"

    return {
        /**
         * Method used to perform login with the given credentials. Note that credential verification is not 
         * performed at this time; it is delayed until the first interaction with the API.
         * 
         * @param {string} username - the username
         * @param {string} password - the password
         */
        login: (username, password) => {
            let token = getAuthorizationToken(username, password)
            axios.defaults.headers.common['Authorization'] = token
            sessionStorage.setItem(USER_INFO_KEY, username)
            sessionStorage.setItem(AUTH_HEADER_KEY, token)
        },

        isLoggedIn: () => { 
            // Ensuring that axios configuration reflects logged in state
            if (sessionStorage.getItem(AUTH_HEADER_KEY) != null) {
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem(AUTH_HEADER_KEY)
                return true
            }
            return false
        }
    }
}

/**
 * Service used to fetch the navigation info
 * 
 * @param {string} url  - the URL from where the navigation info is to be fetched
 * @returns {Promise}   - a promise that, if successfully resolved, will contain the navigation info
 * @throws {Error}      - if an error occurred
 */
export async function navigationInfoService(url) {
    console.log(`navigationInfoService() ${url}`)
    const response = await axios.get(url)
    return response.data
}

/**
 * Function used to obtain the service used to fetch information regarding patients
 */
export function getPatientsService() {
    return {
        /**
         * Method used to get all registered patients
         * @param {string} url  - the URL from where the list of patients is to be fetched
         * @return {Promise}    - a promise that, if successfully resolved, will contain the list of existing patients
         */
        getAll: async (url) => {
            console.log(`PatientsService.getAll() ${url}`)
            const response = await axios.get(url, { 
                headers : { Accept: 'application/vnd.collection+json' } 
            })
            return response.data
        },

        /**
         * Method used to register a new patient
         * @param {string} url      - the URL to where the request is to be issued
         * @param {object} patient  - an object bearing the patient information
         * @return {Promise}        - a promise that, if successfully resolved, will contain the newly registered patient info
         */
        registerPatient: async (url, patient) => {
            console.log(`PatientsService.registerPatient() ${url}`)
            const response = await axios.post(url, patient, { 
                headers: { 
                    'Content-Type': 'application/vnd.collection+json',
                    'Accept': 'application/vnd.collection+json'
                }
            })
            return response.data
        },

        /**
         * Method used to get a given patient information
         * @param {string} url      - the URL to where the request is to be issued
         * @return {Promise}        - a promise that, if successfully resolved, will contain the patient information
         */
        getPatient: async (url) => {
            console.log(`PatientsService.getPatient() ${url}`)
            const response = await axios.get(url, { 
                headers: { 
                    'Accept': 'application/vnd.collection+json'
                } 
            })
            return response.data
        },

        /**
         * Method used to get the status of all patients that registered an expected heartbeat
         * @param {string} url  - the URL from where the status is to be fetched
         * @return {Promise}    - a promise that, if successfully resolved, will contain the list of  patients' status
         */
        getStatus: async (url) => {
            console.log(`PatientsService.getStatus() ${url}`)
            const response = await axios.get(url, { 
                headers: { 
                    'Accept': 'application/vnd.collection+json'
                } 
            })
            return response.data
        }
    }
}

/**
 * Function used to obtain the service used to fetch information regarding alerts
 */
export function getAlertsService() {
    return {
        /**
         * Method used to get all active alerts
         * @param {string} url  - the URL from where the list of alerts is to be fetched
         * @return {Promise}    - a promise that, if successfully resolved, will contain the list of active alerts
         */
        getAllActive: async (url) => {
            console.log(`AlertsService.getAll() ${url}`)
            const response = await axios.get(url, { 
                headers : { Accept: 'application/vnd.collection+json' } 
            })
            return response.data
        },

        /**
         * Method used to dismiss a given active alert
         * @param {string} url  - the URL of the alert to be dismissed
         */
        dismissAlert: async (url) => {
            console.log(`AlertsService.dismissAlert() ${url}`)
            const response = await axios.patch(url)
            return response
        }
    }
}