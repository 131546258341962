import React from 'react'

/**
 * Component that renders the splash page, which is displayed while the navigation info is being fetched.
 * 
 * @prop {function} asyncFetchNavigationInfo    - function to be used to fetch assynchronously the navigation info.
 * @prop {function} infoLoadedCallback          - function to be called once the navigation info was successfully loaded
 * @prop {function} onCredentialsError          - function to be called if an authorization error occurs while fetching navigation data
 */
class SplashPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { message: 'Loading ...' }
    }

    async componentDidMount() {
        try { 
            const navigationInfo = await this.props.asyncFetchNavigationInfo()
            this.props.infoLoadedCallback(navigationInfo)
        }
        catch (error) { 
            if (error.response && error.response.status === 401) {
                this.props.onCredentialsError()
                return
            }
            this.setState({ message: 'ERROR: Could not reach the Web API' }) 
        }
    }

    render() {
        const messageStyle = { textAlign: 'center', fontSize: '30pt' }
        return (<>
            <div className='ui divider'></div>
            <img src='/images/heart_logo.png' className='ui centered medium image' alt='' />
            <p id='status' style={messageStyle}> {this.state.message} </p>
            <div className='ui divider'></div>
        </>)
    }
}

export default SplashPage