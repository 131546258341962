import React from 'react'
import { DismissableMessage } from '../common/messages';
import moment from 'moment'

/**
 * Creates an instance of the alert view model from the given alert DTO
 * @param {object} alert  - the DTO bearing the alert data
 */
export function alertViewModelFromDto(alert) {
    function getPropertyAsString(propertyName, maxLength) {
        let propertyValue = getPropertyValue(propertyName)
        return propertyValue && propertyValue.length > maxLength ? 
        propertyValue.substring(0, maxLength) + "..." : propertyValue
    }

    function getPropertyValue(propertyName) {
        let property = alert.data.find((it) => it.name === propertyName);
        return property ? property.value : property
    }

    return {
        getTitle: () => getPropertyValue('title'),
        getDescription: () => getPropertyAsString('description', 175),
        getCreationDate: () => Date.parse(getPropertyValue('creationDate')),
        getSource: () => getPropertyValue('source'),
        getPatientName: () => getPropertyValue('patientName'),
        getPatientSystem: () => getPropertyValue('patientSystem'),
        getPatientOrganization: () => getPropertyValue('patientOrganization')
    }
}

/**
 * Component used to display a dismissable alert
 * @prop {object} alertDto      - a prop with the collection Json object bearing the alert information
 * @prop {function} onDismissed - a prop with the function that will be called whenever the alert is to be dismissed
 *                                The function arguments are:
 *                                  @param {object} item - the alert item (the DTO)
 *                                  @param {function} completion - the function that is called when the dismissal action is completed. It receives a boolean 
 *                                                                 indicating whether the action was successfully completed or not.
 * @prop {function} onSelected  - a prop with the function that will be called whenever the alert card is clicked
 *                                The function arguments are:
 *                                  @param {object} item - the alert item (the DTO)
 */
class AlertCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = { dismissed: false, disabled: false }
    }

    render() {
        if (this.state && this.state.dismissed)
            return null

        const alert = alertViewModelFromDto(this.props.alertDto)
        const buttonClass = this.state && this.state.disabled ? 'ui right floated basic red disabled button' : 'ui right floated basic red button'
        return (
            <div className='ui red card'>
                <div className='content'>
                    <div className='header'>{alert.getTitle()}</div>
                    <div className='meta'>{alert.getPatientName()} : {alert.getPatientSystem()} </div>
                    <div className='description'>{alert.getDescription()}</div>
                </div>

                <div className='extra content'>
                    <span className={buttonClass} onClick={(evt) => {
                            evt.stopPropagation()
                            this.setState({disabled: true})
                            this.props.onDismissed(this.props.alertDto, (success) => { 
                                if(success) this.setState({dismissed: true})
                                else this.setState({disabled: false})
                            })
                        } 
                    }>
                    <i className='close icon'></i>&nbsp;&nbsp;Dismiss
                    </span>
                    <span className='left floated'>
                        {moment(alert.getCreationDate()).fromNow()}
                    </span>
                </div>
            </div>
        )
    }
}

/**
 * Component used to display a grid of alerts'
 * @prop {object} loadedData            - a prop with the collection Json object bearing the alerts' list
 * @prop {function} onAlertSelected     - a prop with the function that will be called whenever an alert card is clicked
 *                                      The function arguments are:
 *                                          @param {object} item - the alert item (the DTO)
 * @prop {function} onDismissed          - a prop with the function that will be called whenever an alert card is clicked
 *                                      The function arguments are:
 *                                          @param {object} item - the alert item (the DTO)
 */
export class AlertsGrid extends React.Component {

    render() {
        return this.props && this.props.loadedData.items.length === 0 ? 
            <DismissableMessage title='Relax, all is well' message='There are no reported alerts on our systems.' positive /> : 
            <div className='ui container middle'>
                <div className='ui centered cards'>
                    { this.props.loadedData.items.map((item) => <AlertCard alertDto={item} key={item.href} 
                            onSelected={this.props.onAlertSelected} onDismissed={this.props.onAlertDismissed} />) 
                    }
                </div>
            </div>
    }
}
