import React from 'react'
import { Redirect } from 'react-router-dom'

import { Spinner, ErrorMessage } from '../common/messages'
import { FormTextInput, HeartRateInput, EmailInput } from './common'

/**
 * Helper function used to map an entry of the Collection JSON template object received from the API to a form input. 
 * This is the function that actually performs the required projection.
 * 
 * @param {object} item             - the item from the Collection JSON template object
 * @param {object} state            - the current state of the form
 * @param {function} changeHandler  - the function to be used as an event handler for changes on the form elements
 * @return {React.Component}        - the corresponding form component
 */
function templateItemToFormElement(item, state, changeHandler) {
    switch (item.name) {
        case 'heartRate':
            return <HeartRateInput descriptor={item} mandatory={false} readOnly={false} key={item.name} state={state} changeHandler={changeHandler} />    
        case 'alertEmails':
            return <EmailInput descriptor={item} mandatory={false} readOnly={false} key={item.name} state={state} changeHandler={changeHandler} />    
        default:
            return <FormTextInput descriptor={item} mandatory={true} readOnly={false} key={item.name} state={state} changeHandler={changeHandler} />
    }
}

/**
 * Controlled component that represents the form used to create new patients.
 * 
 * @prop {string} successRoute  - the route to where the UI will be redirected upon successful form submission
 * @prop {function} service     - the async function to be used to register the patient on the web API
 */
export default class NewPatientForm extends React.Component {

    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = { submitted: false, shouldRedirect: false, error: false, formData: { units: 'minutes' } }
    }

    buildPatientFromState() {
        let heartRate = undefined
        if (this.state.formData.heartRate && this.state.formData.units) {
            heartRate = this.state.formData.heartRate
            switch (this.state.formData.units) {
                case 'days':
                    heartRate *= 24
                    // falls through
                case 'hours':
                    heartRate *= 60
                // no default
            }
        }

        const result = Object.entries(this.state.formData)
                        .filter((pair) => pair[0] !== 'units')
                        .map((entry) => entry[0] === 'heartRate' ? 
                            ({ name: 'heartRate', value: heartRate}) :
                            ({ name: entry[0], value: entry[1] })
                        )

        return { template: { data: result }}
    }
    
    handleChange(event) {
        const addToState = this.state.formData
        addToState[event.target.name] = event.target.value
        this.setState({ formData: addToState })
    }
    
    async handleSubmit(event) {
        event.preventDefault()
        try {
            this.setState({submitted: true})
            await this.props.service(this.buildPatientFromState())
            this.setState({shouldRedirect: true, submitted: false})
        }
        catch (error) {
            this.setState({ error, submitted: false })
        }
    }
        
    render() {

        if (this.state.shouldRedirect) {
            return <Redirect to={this.props.successRoute} />
        }

        let message = undefined
        if (this.state.submitted) {
            message = <Spinner title="We are registering the new patient" message="Please hold while we are contacting our API" />
        }
        else if (this.state.error) {
            message = <ErrorMessage title="Oops! We could not make the registration" message="Something went wrong while contacting our API" />
        }

        return (
            <form className='ui equal width form' onSubmit={this.handleSubmit}>
                <div className='ui segment'>
                    <div className='ui container'>
                        {message}
                        <h4 className='ui dividing header'>Patient Information</h4>
                        {this.props.template.data.map((item) => templateItemToFormElement(item, this.state.formData, this.handleChange))}
                    </div>
                </div>
                <div className='ui container'>
                    <button className='ui right floated primary basic blue button' type='submit' disabled={this.state.submitted}>
                        <i className='user plus icon'></i>Submit
                    </button>
                    <button className='ui right floated basic blue button' type='button' onClick={() => { window.history.back() } } >
                        <i className='arrow left icon'></i>Cancel
                    </button>
                </div>
            </form>
        )
    }
}