import React from 'react'
import Menu from './common/menu'

import { withLoader } from './common/loader'
import { AlertsGrid, alertViewModelFromDto } from './alerts/alertsList'
import { ErrorMessage } from './common/messages'

import { displayAlert } from '../notifications'

/**
 * Internal component used to render the home page contents.
 * 
 * @prop {string} author        - the name of the author
 * @prop {string} title         - the name of the application
 * @prop {string} description   - a short description of the application
 * @prop {string} imageSrc      - the url of the image to be displayed
 */
function Content({ author, title, description, imageSrc }) {
    const titleTextStyle = { marginBottom: '0.3em' }
    const descriptionTextStyle = { marginBottom: '0.7em' }
    return (
        <div className='ui segment'>
            <div className='ui two column grid container'>
                <div className='column middle aligned'>
                    <div className='ui small image floated right'><img src={imageSrc} alt={titleTextStyle}/></div>
                </div>
                <div className='column middle aligned'>
                    <h2 style={titleTextStyle}>{title}</h2> 
                    <div style={descriptionTextStyle}>{description}</div>
                    <a className='ui mini basic spaced label' href={author.email}> by {author.name}</a>
                </div>
            </div>
        </div>
    )
}

const RELOAD_INTERVAL_IN_MINUTES = 1

/**
 * Component that represents the application's home page
 * 
 * @prop {object} content   - a prop bearing the navigation information (i.e. the API's home resource) used to build the navigation menu
 * @prop {string} activeKey - a prop bearing the key of the currently active menu item
 * @prop {object} service   - a prop bearing the Alerts service to be used (an injected dependency)
 */
class HomePage extends React.Component {

    notifyNewAlerts(receivedAlerts) {
        let alertsToNotify = receivedAlerts
        if (this.state && this.state.loadedData) {
            // Notify only new alerts
            alertsToNotify = receivedAlerts.filter(
                (alertDto) => !this.state.loadedData.items.find((item) => item.href === alertDto.href)
            )
        }

        if (alertsToNotify.length === 1) {
            const alert = alertViewModelFromDto(alertsToNotify[0])
            displayAlert(alert.getTitle(), `From ${alert.getPatientName()} : ${alert.getPatientSystem()} : ${alert.getPatientOrganization()}`)
        }
        else if (alertsToNotify.length !== 0)
            displayAlert('Alerts received!', 'Our systems require attention')
    }

    handleAlertSelected = (item) => {
        console.log('Alert was selected')
        console.log(item)
    }

    handleAlertDismissed = async (item, completionCallback) => {
        try {
            await this.props.service.dismissAlert(item.href)
            completionCallback(true)
        }
        catch(error) {
            this.setState({webAPIUnreachable: true})
            completionCallback(false)
        }
    }

    async componentWillMount() {
        const alertsUrl = this.getAlertsResourceUrl()
        if (alertsUrl) {
            this.AlertsLoader = withLoader(AlertsGrid, RELOAD_INTERVAL_IN_MINUTES,
                async () => this.props.service.getAllActive(alertsUrl), 
                (data) => { 
                    if (data) {
                        this.notifyNewAlerts(data.items)
                        this.setState({loadedData: data, webAPIUnreachable: false}) 
                    }
                    else {
                        this.setState({webAPIUnreachable: true})
                    }
                }
            )
        }
    }

    getAlertsResourceUrl() {
        const resourceDescriptor = this.props.content.resources['alerts']
        return resourceDescriptor && resourceDescriptor.href
    }

    render() {
        const unreachableAPIError = !(this.state && this.state.loadedData && this.state.webAPIUnreachable) ? undefined :
                <ErrorMessage title='Uh Oh' message='We failed to reach our API' />
        return (
            <>
                <Menu items={this.props.content.items} activeKey={this.props.activeKey} />
                <Content 
                    imageSrc='images/heart_logo.png'
                    title='Heimdallr'
                    description='Application Health Monitoring' 
                    author={{ email: this.props.content.api.links.author, name: 'Challenge.IT' }} />

                <h4 className='ui horizontal clearing divider header'>
                    <i className='ambulance icon'></i> Alerts
                </h4>
                <div className='ui one column grid container'>
                    <div className='column middle aligned'>
                        {unreachableAPIError}
                        <this.AlertsLoader onAlertSelected={this.handleAlertSelected} onAlertDismissed={this.handleAlertDismissed} />
                    </div>
                </div>
            </>
        )
    }
}

export default HomePage