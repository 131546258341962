import React from 'react'
import Menu from '../common/menu'

import { Route, Link, Redirect, Switch } from 'react-router-dom'
import { withLoader } from '../common/loader'

import NewPatientForm from './newPatientForm'
import PatientPage from './patientPage'
import { patientViewModelFromDto } from './viewModels'

export const PATIENTS_ROUTE = '/patients'
const NEW_PATIENT_FORM_ROUTE = '/patients/new'
const PATIENT_ROUTE = '/patients/:id'

/**
 * Determines whether the given path matches a patient route
 * @param {string} route - The path to be evaluated
 * @returns A boolean value indicating whether the given path matches a patient route or not
 */
function isPatientRoute(route) {
    const patientRouteSegments = PATIENT_ROUTE.split('/')
    const routeSegments = route.split('/')
    return patientRouteSegments.length === routeSegments.length && patientRouteSegments[0] === routeSegments[0]
}

/**
 * Converts the given patient identifier to its corresponding client route
 * @param   {string} id - The identifier of the patient
 * @returns {string}    - The client side route tho be used to display the given patient resource
 */
function mapPatientIdToClientRoute(id) {
    return `${PATIENTS_ROUTE}/${id}`
}

/**
 * Internal component used to display the patients' list
 * 
 * @prop {object} loadedData    - a prop with the collection Json object bearing the patients' list
 * @prop {function} onItemClick - a prop with the function that will be called whenever a list item is clicked. 
 *                                The function arguments are:
 *                                  @param {object} item - the list item
 *                                  @param {event} event - the original click event
 */
function PatientsList({loadedData, onItemClick}) {
    function itemToListEntry(item) {
        let patient = patientViewModelFromDto(item)
        return (
            <div className='item' key={item.href} onClick={(event) => onItemClick(item, event)}>
              <i className='big id badge icon'></i>
              <div className='content'>
                <div className='header'>
                    <Link 
                        onClick={(e) => {e.stopPropagation(); e.preventDefault(); onItemClick(item, e)}} 
                        to={ mapPatientIdToClientRoute(patient.getId()) }>
                            {patient.getName()}
                    </Link>
                </div>
                <div className='description'>
                    {patient.getOrganization()} : &nbsp;{patient.getSystem()} 
                </div>
              </div>
            </div>
        )
    }

    return (        
        <div className='ui middle aligned animated selection list container'>
            { loadedData.items.map(itemToListEntry) }
        </div>
    )
}

/**
 * Component that represents the application's patients page
 * 
 * @prop {object} content   - a prop bearing the navigation information (i.e. the API's home resource) used to build the navigation menu
 * @prop {string} activeKey - a prop bearing the key of the currently active menu item
 * @prop {object} service   - a prop bearing the Patients service to be used (an injected dependency)
 */
export class PatientsPage extends React.Component {

    constructor(props) {
        super(props)
        this.handleItemClick = this.handleItemClick.bind(this)
    }
    
    componentWillMount() {
        const patientsUrl = this.getPatientsResourceUrl()
        if (patientsUrl) {
            this.PatientsListLoader = withLoader(PatientsList, false,
                async () => this.props.service.getAll(patientsUrl), 
                (data) => { this.setState({loadedData: data}) }
            )
        }
    }

    getPatientsResourceUrl() {
        const resourceDescriptor = this.props.content.resources['patients']
        return resourceDescriptor && resourceDescriptor.href
    }

    handleItemClick(item, _event) {
        const id = item.data.find((it) => it.name === 'id') 
        this.setState({redirect: mapPatientIdToClientRoute(id ? id.value : undefined), patientItem: item })
    }

    componentDidUpdate(_prevProps, prevState, _snapshot) {
        // Clearing redirect related state to enable back navigation
        if (prevState && prevState.redirect)
            this.setState({redirect: undefined, patientItem: undefined})
    }

    shouldRedirect() {
        return this.state && this.state.redirect && this.props.location.pathname !== this.state.redirect
    }

    getCurrentPatient() {
        let patient = this.state ? this.state.patientItem : undefined
        if (!patient && this.props.location.pathname !== PATIENTS_ROUTE && this.props.location.pathname !== NEW_PATIENT_FORM_ROUTE) {
            // Lets determine which is the selected patient
            const parts = this.props.location.pathname.split('/')
            const patientId = parts[parts.length-1]
            patient = this.state.loadedData.items.find((it) => patientViewModelFromDto(it).getId() === patientId)
        }
        return patient
    }

    renderLoader() {
        return (<>
            <Menu items={this.props.content.items} activeKey={this.props.activeKey} />
            <Route path={this.props.activeKey} 
                render={(_props) => (<>
                    <div className='ui segment'>
                        <div className='ui container'>
                            <this.PatientsListLoader onItemClick={this.handleItemClick} />
                        </div>
                    </div> 
                </>)} />
        </>)
    }
    
    /**
     * Method used to render the component when it is displaying a patient's list (either already loaded or in the process of loading)
     */
    renderPatientsList() {
        
        let button;
        let formRoute;
        if(this.state && this.state.loadedData && this.state.loadedData.template) {
            button = (
                <div className='ui container'>
                    <div className='ui right floated basic blue button' data-tooltip='Register a new patient'
                        data-position='left center'>
                        <Link to={NEW_PATIENT_FORM_ROUTE}><i className='user plus icon'></i> New </Link>
                    </div>
                </div>        
            )
            formRoute = (
                <Route exact path={NEW_PATIENT_FORM_ROUTE} render={(props) => 
                    <NewPatientForm {...props} template={this.state.loadedData.template} 
                            service={(data) => this.props.service.registerPatient(this.getPatientsResourceUrl(), data)} 
                            successRoute={PATIENTS_ROUTE} /> 
                }/>                
            )
            
        }
        
        const childrenRoutes = !this.state || !this.state.loadedData ? undefined : (
            <Switch>
                {formRoute}
                <Route path={PATIENT_ROUTE} render={ (props) => <PatientPage {...props} patient={this.getCurrentPatient()} service={this.props.service} /> } />
            </Switch>
        )
        
        return (
            <>
                <Menu items={this.props.content.items} activeKey={this.props.activeKey} />
                <Route path={this.props.activeKey} 
                    render={(_props) => (<>
                        <div className='ui segment'>
                            <div className='ui container'>
                                <this.PatientsListLoader onItemClick={this.handleItemClick} />
                            </div>
                        </div> 
                        {button}
                    </>)} />
                {childrenRoutes}
            </>        
        )
    }    

    /**
     * Method used to render the component when it is displaying the form for registering a new patient
     */
    renderNewPatientForm() {
        if (this.props.match.path === PATIENTS_ROUTE) {
            // Lets ensure that the required data is loaded
            if (!this.state || !this.state.loadedData)
                return this.renderLoader()
        }
        return ( <>
            <Menu items={this.props.content.items} activeKey={this.props.activeKey} />
            <NewPatientForm template={this.state.loadedData.template} 
                    service={(data) => this.props.service.registerPatient(this.getPatientsResourceUrl(), data)} 
                    successRoute={PATIENTS_ROUTE} /> 
        </>)
    }

    /**
     * Method used to render the component when it is displaying the information of a given patient
     */
    renderPatientPage() {
        // Lets ensure that the required data is loaded
        if (!this.state || !this.state.loadedData) {
            return this.renderLoader()
        }
        return (
            <>
                <Menu items={this.props.content.items} activeKey={this.props.activeKey} />
                <Route path={PATIENT_ROUTE} render={ (props) => <PatientPage {...props} patient={this.getCurrentPatient()} service={this.props.service} /> } />
            </>        
        )
    }

    render() {
        if (this.shouldRedirect()) {
            return <Redirect push to={this.state.redirect} />
        }

        switch (this.props.location.pathname) {
            case PATIENTS_ROUTE:
                return this.renderPatientsList()
            case NEW_PATIENT_FORM_ROUTE:
                return this.renderNewPatientForm()
            default:
                return isPatientRoute(this.props.location.pathname) ? 
                    this.renderPatientPage() : 
                    <Redirect to="/" />
        }
    }
}
